<template>
    <div class="flex flex-col flex-1 overflow-hidden">
        <PageTitle title="Settings">
            <button-group
                v-if="isAdmin"
                :options="tabOptions"
                :active-item="selectedTab"
                shadow
                @change-filter="changeTab"
            />
        </PageTitle>
        <div v-if="selectedTab === '1'" class="p-8 space-y-8">
            <Form
                :validation-schema="validationUserSchema"
                ref="userForm"
                class="md:grid md:grid-cols-3 md:gap-6"
                v-slot="{ meta }"
            >
                <div class="md:col-span-1">
                    <div class="px-4 sm:px-0">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">Personal Details</h3>
                        <p class="mt-1 text-sm text-gray-600">
                            Basic information about the user
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <form action="#" method="POST">
                        <div class="overflow-hidden shadow sm:rounded-md">
                            <div class="px-4 py-5 bg-white sm:p-6">
                                <div class="grid grid-cols-6 gap-6">
                                    <div class="col-span-6 sm:col-span-3">
                                        <InputField
                                            name="firstName"
                                            label="First Name"
                                            v-model="user.firstName"
                                            placeholder="Enter First Name"
                                            type="text"
                                        />
                                    </div>

                                    <div class="col-span-6 sm:col-span-3">
                                        <InputField
                                            name="lastName"
                                            label="Last Name"
                                            placeholder="Enter Last Name"
                                            v-model="user.lastName"
                                            type="text"
                                        />
                                    </div>

                                    <div class="col-span-6 sm:col-span-3">
                                        <FormItem label="Email" :value="user.email" />
                                    </div>

                                    <div class="col-span-6 sm:col-span-3">
                                        <FormItem label="Username" :value="user.username" />
                                    </div>
                                </div>
                            </div>
                            <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
                                <TwButton
                                    variant="secondary"
                                    class="inline-flex justify-center "
                                    @click="updateUser"
                                    :disabled="!meta.valid"
                                >
                                    <IdentificationIcon class="w-5 h-5 mr-2" />Update Details
                                </TwButton>
                            </div>
                        </div>
                    </form>
                </div>
            </Form>

            <Form
                :validation-schema="validationSchema"
                ref="form"
                class="md:grid md:grid-cols-3 md:gap-6"
                v-slot="{ meta }"
            >
                <div class="md:col-span-1">
                    <div class="px-4 sm:px-0">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">Change Password</h3>
                        <p class="mt-1 text-sm text-gray-600">
                            Update your password, by providing the requested details
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <form action="#" method="POST">
                        <div class="overflow-hidden shadow sm:rounded-md">
                            <div class="px-4 py-5 bg-white sm:p-6">
                                <div class="grid grid-cols-6 gap-6">
                                    <div class="col-span-6 sm:col-span-3">
                                        <InputField
                                            name="password"
                                            label="Current Password"
                                            v-model="passwordChange.password"
                                            placeholder="Enter Current Password"
                                            type="password"
                                            min="8"
                                            max="24"
                                        />
                                    </div>

                                    <div class="col-span-6 sm:col-span-3">
                                        <InputField
                                            name="newPassword"
                                            label="New Password"
                                            placeholder="Enter New Password"
                                            v-model="passwordChange.newPassword"
                                            type="password"
                                            min="8"
                                            max="24"
                                        />
                                    </div>

                                    <div class="col-span-6 sm:col-span-3">
                                        <InputField
                                            name="repeatPassword"
                                            label="Confirm New Password"
                                            placeholder="Confirm New Password"
                                            v-model="passwordChange.repeatPassword"
                                            type="password"
                                            min="8"
                                            max="24"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
                                <TwButton
                                    variant="secondary"
                                    class="inline-flex justify-center "
                                    :disabled="!meta.valid"
                                    @click="changePassword"
                                >
                                    <key-icon class="w-5 h-5 mr-2" />Update Password
                                </TwButton>
                            </div>
                        </div>
                    </form>
                </div>
            </Form>
        </div>

        <div v-if="isAdmin && selectedTab === '2'" class="p-8 space-y-8">
            <Form
                :validation-schema="validationNewUserSchema"
                ref="newUserForm"
                class="md:grid md:grid-cols-3 md:gap-6"
                v-slot="{ meta }"
            >
                <div class="md:col-span-1">
                    <div class="px-4 sm:px-0">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">User Registration</h3>
                        <p class="mt-1 text-sm text-gray-600">
                            Register a new user, by providing the requested details
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <form action="#" method="POST">
                        <div class="overflow-hidden shadow sm:rounded-md">
                            <div class="px-4 py-5 bg-white sm:p-6">
                                <div class="grid grid-cols-6 gap-6">
                                    <div class="col-span-6 sm:col-span-3">
                                        <InputField
                                            name="firstName"
                                            label="First Name"
                                            v-model="newUser.firstName"
                                            placeholder="Enter First Name"
                                            type="text"
                                        />
                                    </div>

                                    <div class="col-span-6 sm:col-span-3">
                                        <InputField
                                            name="lastName"
                                            label="Last Name"
                                            placeholder="Enter Last Name"
                                            v-model="newUser.lastName"
                                            type="text"
                                        />
                                    </div>

                                    <div class="col-span-6 sm:col-span-3">
                                        <InputField
                                            name="email"
                                            label="Email"
                                            placeholder="Enter email address"
                                            v-model="newUser.email"
                                            type="email"
                                        />
                                    </div>

                                    <div class="col-span-6 sm:col-span-3">
                                        <InputField
                                            name="username"
                                            label="Username"
                                            placeholder="Enter username"
                                            v-model="newUser.username"
                                            type="text"
                                        />
                                    </div>

                                    <div class="col-span-6 sm:col-span-3">
                                        <InputField
                                            name="password"
                                            label="Password"
                                            v-model="newUser.password"
                                            placeholder="Enter Password"
                                            type="password"
                                            min="8"
                                            max="24"
                                        />
                                    </div>

                                    <div class="col-span-6 sm:col-span-3">
                                        <InputField
                                            name="passwordConfirmation"
                                            label="Repeat Password"
                                            placeholder="Repeat Password"
                                            v-model="newUser.passwordRepeat"
                                            type="password"
                                            min="8"
                                            max="24"
                                        />
                                    </div>

                                    <div class="col-span-6 sm:col-span-3">
                                        <MultiselectField
                                            label="Type"
                                            placeholder="Select User Type"
                                            name="userType"
                                            id="userType"
                                            :options="types"
                                            v-model="newUser.type"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
                                <TwButton
                                    variant="secondary"
                                    class="inline-flex justify-center "
                                    @click="registerUser"
                                    :disabled="!meta.valid"
                                >
                                    <UserAddIcon class="w-5 h-5 mr-2" />Register User
                                </TwButton>
                            </div>
                        </div>
                    </form>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda';
import { defineComponent, ref, computed } from 'vue';
import { PageTitle, TwButton, InputField, FormItem, ButtonGroup, MultiselectField } from '@/components';
import { Form, defineRule } from 'vee-validate';
import { required, min, max, confirmed, email } from '@vee-validate/rules';
import { KeyIcon, IdentificationIcon, UserAddIcon } from '@heroicons/vue/outline';
import store from '@/store';
import { useToast } from 'vue-toastification';
import AuthAPI from '../api/auth';

defineRule('required', required);
defineRule('confirmed', confirmed);
defineRule('min', min);
defineRule('max', max);
defineRule('email', email);

export default defineComponent({
    name: 'Settings',
    components: {
        PageTitle,
        TwButton,
        KeyIcon,
        UserAddIcon,
        Form,
        InputField,
        FormItem,
        ButtonGroup,
        IdentificationIcon,
        MultiselectField,
    },
    setup() {
        const types = ref(['Aggregator', 'Building Occupant']);
        const form = ref();
        const userForm = ref();
        const newUserForm = ref();
        const selectedTab = ref('1');
        const tabOptions = [
            { text: 'Personal Details', value: '1' },
            { text: 'User Registration', value: '2' },
        ];
        const toast = useToast();
        const user = ref(R.clone(store.state.user));
        const isAdmin = computed(() => user.value && user.value.roles.includes('admin'));
        const passwordChange = ref({
            password: null,
            newPassword: null,
            repeatPassword: null,
        });
        const newUser = ref({
            firstName: null,
            lastName: null,
            email: null,
            username: null,
            password: null,
            passwordRepeat: null,
            type: null,
        });

        const clearNewUser = () => {
            newUser.value = {
                firstName: null,
                lastName: null,
                email: null,
                username: null,
                password: null,
                passwordRepeat: null,
                type: null,
            };
        };

        const validationSchema = computed(() => {
            return {
                password: 'required|min:8|max:24',
                newPassword: 'required|min:8|max:24',
                repeatPassword: 'required|min:8|max:24|confirmed:@newPassword',
            };
        });

        const validationUserSchema = computed(() => {
            return {
                firstName: 'required',
                lastName: 'required',
                // email: 'required',
            };
        });

        const validationNewUserSchema = computed(() => {
            return {
                firstName: 'required',
                lastName: 'required',
                email: 'required|email',
                username: 'required',
                password: 'required|min:8|max:24',
                passwordConfirmation: 'required|min:8|max:24|confirmed:@password',
                userType: 'required',
            };
        });

        const changePassword = async () => {
            const valid = await form.value.validate();
            if (valid) {
                AuthAPI.changePassword(passwordChange.value)
                    .then(() => {
                        toast.success('Password has been changed successfully!');
                    })
                    .catch(() => {
                        toast.error('Invalid password');
                    })
                    .finally(() => {
                        passwordChange.value = { password: null, newPassword: null, repeatPassword: null };
                    });
            }
        };

        const updateUser = async () => {
            const valid = await userForm.value.validate();
            if (valid) {
                AuthAPI.updateUser(user.value)
                    .then(() => {
                        store.commit('SET_USER', user.value);
                        toast.success('User Details have been changed successfully!');
                    })
                    .catch(() => {
                        toast.error('An error has occurred while updating user details.');
                    });
            }
        };

        const registerUser = async () => {
            const valid = await newUserForm.value.validate();
            if (valid) {
                AuthAPI.registerUser(newUser.value)
                    .then(() => {
                        clearNewUser();
                        toast.success('User has been registered successfully!');
                    })
                    .catch(() => {
                        toast.error('An error has occurred while registering new user.');
                    });
            }
        };

        const changeTab = tab => {
            selectedTab.value = tab;
        };

        return {
            passwordChange,
            validationSchema,
            form,
            changePassword,
            user,
            validationUserSchema,
            userForm,
            updateUser,
            isAdmin,
            selectedTab,
            tabOptions,
            changeTab,
            validationNewUserSchema,
            newUser,
            types,
            newUserForm,
            registerUser,
        };
    },
});
</script>
